import {add, init, logEvent} from '@amplitude/analytics-browser';
import {Injectable} from '@angular/core';
import {Product} from '@app/core/models/account/product';
import {IGridsterItem} from '@app/core/models/gridster';
import {Environment} from '@env/environment.entities';
import * as cryptoJs from 'crypto-js';

import {EEventType} from './enums/event-type.enum';
import {EHelpdeskTicketAction} from './enums/helpdesk-ticket-action.enum';
import {IAmplitudeConfig} from './interfaces/amplitude-config.interface';
import {IDefaultEventProperties} from './interfaces/default-event-properties.interface';
import {UserPropertiesEnrichmentPlugin} from './plugins/user-properties-enrichment-plugin';
import {
  TAmplitudeDepositProperties,
  TAmplitudeExchangeProperties,
  TAmplitudeInternalWithdrawalProperties,
  TAmplitudeTransferProperties,
  TAmplitudeWithdrawalProperties,
  TAmplitudTransactionStatus,
} from './types/event.type';

@Injectable({providedIn: 'root'})
export class AmplitudeService {
  private defaultEventProperties: IDefaultEventProperties | null = null;

  constructor(private readonly environment: Environment) {}

  public initialize(config: IAmplitudeConfig): void {
    const encryptedEmail = cryptoJs.SHA256(config.email);

    this.setDefaultEventProperties(config);
    this.setPlugins();

    init(this.environment.amplitudeApiKey, encryptedEmail.toString(), {
      defaultTracking: {
        ['pageViews']: false,
        ['attribution']: false,
        ['formInteractions']: false,
        ['fileDownloads']: false,
      },
    });
  }

  public logDepositEvent(properties: TAmplitudeDepositProperties & TAmplitudTransactionStatus): void {
    this.logEvent(EEventType.Deposit, {
      ...(properties.amount !== undefined ? {['Amount']: properties.amount} : {}),
      ['Currency']: properties.currency,
      ['Provider name']: properties.providerName,
      ['Status']: properties.status,
    });
  }

  public logWithdrawalEvent(properties: TAmplitudeWithdrawalProperties & TAmplitudTransactionStatus): void {
    this.logEvent(EEventType.Withdrawal, {
      ['Amount']: properties.amount,
      ['Currency']: properties.currency,
      ['Provider name']: properties.providerName,
      ['Status']: properties.status,
    });
  }

  public logInternalWithdrawalEvent(
    properties: TAmplitudeInternalWithdrawalProperties & TAmplitudTransactionStatus,
  ): void {
    this.logEvent(EEventType.InternalWithdrawal, {
      ['Amount']: properties.amount,
      ['Currency']: properties.currency,
      ['Status']: properties.status,
    });
  }

  public logTransferEvent(properties: TAmplitudeTransferProperties & TAmplitudTransactionStatus): void {
    this.logEvent(EEventType.Transfer, {
      ['Amount']: properties.amount,
      ['Currency']: properties.currency,
      ['Status']: properties.status,
    });
  }

  public logExchangeEvent(properties: TAmplitudeExchangeProperties & TAmplitudTransactionStatus): void {
    this.logEvent(EEventType.Exchange, {
      ['From currency']: properties.fromCurrency,
      ['To currency']: properties.toCurrency,
      ['Exchange rate']: properties.exchangeRate,
      ['Amount from']: properties.amountFrom,
      ['Amount to']: properties.amountTo,
      ['Status']: properties.status,
    });
  }

  public logHelpdeskTicketActionEvent(action: EHelpdeskTicketAction): void {
    this.logEvent(EEventType.HelpdeskTicketAction, {
      action,
    });
  }

  public logSuccessfulVerificationRequestEvent(currentVerificationIndex: number): void {
    const nextVerificationIndex = currentVerificationIndex + 1;

    this.logEvent(EEventType.SuccessfulVerificationRequest, {
      ['desired_verification_level']: nextVerificationIndex,
    });
  }

  public logAddWidgetToDashboardEvent(widget: Partial<IGridsterItem>): void {
    this.logEvent(EEventType.AddWidgetToDashboard, {name: widget.name});
  }

  public logAccountCreatedEvent(product: Product): void {
    this.logEvent(EEventType.AccountCreated, {
      ['platform_class']: product.platform.class,
      ['platform_currency']: product.currency.alpha,
    });
  }

  public logEvent(eventType: EEventType, eventProperties?: Record<string, string | number | boolean>): void {
    if (!this.defaultEventProperties) {
      return;
    }

    logEvent(eventType, eventProperties);
  }

  private setDefaultEventProperties(config: IAmplitudeConfig): void {
    this.defaultEventProperties = {
      vendor: this.environment.projectInfo.name,
      appEnvironmentType: config.appEnvironmentType,
      appVersion: this.environment.appBuild.appVersion,
      appSource: 'v2',
    };
  }

  private setPlugins(): void {
    if (this.defaultEventProperties) {
      add(new UserPropertiesEnrichmentPlugin(this.defaultEventProperties));
    }
  }
}
